
.news-item:hover img.scale-up-bl {
	-webkit-animation: scale-up-bl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-bl 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.shadow-drop-center:hover {
	-webkit-animation: shadow-drop-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-drop-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-6-8 10:29:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-bl
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-bl {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }
  @keyframes scale-up-bl {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }
  


  /* ----------------------------------------------
 * Generated by Animista on 2023-6-8 10:32:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-drop-center
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-center {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  @keyframes shadow-drop-center {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
              box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
              box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }
  

  .article-hovered:hover {
	-webkit-animation: shadow-drop-2-bl 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: shadow-drop-2-bl 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
} 



.article-hovered{
        clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0% 100%);

}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-12 15:1:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-top {
        0% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
        }
        100% {
          -webkit-transform: translateY(-100px);
                  transform: translateY(-100px);
        }
      }
      @keyframes slide-top {
        0% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
        }
        100% {
          -webkit-transform: translateY(-100px);
                  transform: translateY(-100px);
        }
      }
      

/* ----------------------------------------------
 * Generated by Animista on 2023-6-12 14:59:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes scale-up-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      

 /* ----------------------------------------------
 * Generated by Animista on 2023-6-9 11:6:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-drop-2-bl
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-bl {
        0% {
          -webkit-transform: translateZ(0) translateX(0) translateY(0);
                  transform: translateZ(0) translateX(0) translateY(0);
          -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
                  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
        100% {
          -webkit-transform: translateZ(50px) translateX(12px) translateY(-12px);
                  transform: translateZ(50px) translateX(12px) translateY(-12px);
          -webkit-box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
                  box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
        }
      }
      @keyframes shadow-drop-2-bl {
        0% {
          -webkit-transform: translateZ(0) translateX(0) translateY(0);
                  transform: translateZ(0) translateX(0) translateY(0);
          -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
                  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
        100% {
          -webkit-transform: translateZ(50px) translateX(12px) translateY(-12px);
                  transform: translateZ(50px) translateX(12px) translateY(-12px);
          -webkit-box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
                  box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
        }
      }
      