.news-item:hover img.scale-up-bl {
  -webkit-animation: .4s cubic-bezier(.39, .575, .565, 1) both scale-up-bl;
  animation: .4s cubic-bezier(.39, .575, .565, 1) both scale-up-bl;
}

.shadow-drop-center:hover {
  -webkit-animation: .4s cubic-bezier(.25, .46, .45, .94) both shadow-drop-center;
  animation: .4s cubic-bezier(.25, .46, .45, .94) both shadow-drop-center;
}

@-webkit-keyframes scale-up-bl {
  0% {
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }

  100% {
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-bl {
  0% {
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }

  100% {
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 #0000;
    box-shadow: 0 0 #0000;
  }

  100% {
    -webkit-box-shadow: 0 0 20px #00000059;
    box-shadow: 0 0 20px #00000059;
  }
}

@keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 #0000;
    box-shadow: 0 0 #0000;
  }

  100% {
    -webkit-box-shadow: 0 0 20px #00000059;
    box-shadow: 0 0 20px #00000059;
  }
}

.article-hovered:hover {
  -webkit-animation: .4s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-bl;
  animation: .4s cubic-bezier(.25, .46, .45, .94) both shadow-drop-2-bl;
}

.article-hovered {
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 40px), calc(100% - 40px) 100%, 0% 100%);
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(.5);
    transform: scale(.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes shadow-drop-2-bl {
  0% {
    -webkit-transform: translateZ(0)translateX(0)translateY(0);
    transform: translateZ(0)translateX(0)translateY(0);
    -webkit-box-shadow: 0 0 #0000;
    box-shadow: 0 0 #0000;
  }

  100% {
    -webkit-transform: translateZ(50px)translateX(12px)translateY(-12px);
    transform: translateZ(50px)translateX(12px)translateY(-12px);
    -webkit-box-shadow: -12px 12px 20px -12px #00000059;
    box-shadow: -12px 12px 20px -12px #00000059;
  }
}

@keyframes shadow-drop-2-bl {
  0% {
    -webkit-transform: translateZ(0)translateX(0)translateY(0);
    transform: translateZ(0)translateX(0)translateY(0);
    -webkit-box-shadow: 0 0 #0000;
    box-shadow: 0 0 #0000;
  }

  100% {
    -webkit-transform: translateZ(50px)translateX(12px)translateY(-12px);
    transform: translateZ(50px)translateX(12px)translateY(-12px);
    -webkit-box-shadow: -12px 12px 20px -12px #00000059;
    box-shadow: -12px 12px 20px -12px #00000059;
  }
}

/*# sourceMappingURL=index.42dc011f.css.map */
